import CardBody from './CardBody';
import { Text } from '@chakra-ui/react';
import { Chart } from 'react-google-charts';
import Card from './Card';
import { useTranslation } from 'react-i18next';

export default function ConsumptionComparisonGraph() {
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody textAlign={'center'}>
        <Text fontWeight={'bold'} color={'blue.600'} textTransform={'uppercase'}>
          {t('consumption.detail.efficiency.graphTitle')}
        </Text>

        <Chart
          width={'100%'}
          height={'300px'}
          chartType='BarChart'
          loader={<div>Loading Chart</div>}
          data={[
            ['Date', '2021', '2020'],
            ['Aprile', 31, 26],
            ['Maggio', 19, 13],
            ['Giugno', 12, 10],
          ]}
          options={{
            chartArea: { width: '60%' },
          }}
        />
      </CardBody>
    </Card>
  );
}