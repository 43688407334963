import { Box, Hide, Show, SimpleGrid, Text } from '@chakra-ui/react';
import BlueHeading from '../BlueHeading';
import GrayHeading from '../GrayHeading';
import ContractList from '../ContractList';
import { useContext } from 'react';
import { AppContext } from '../App';
import { useTranslation } from 'react-i18next';
import ViewTitle from '../ViewTitle';
import { encodeInvNumber, useBreakPoints } from '../utils';
import { Redirect, useLocation } from 'react-router-dom';

export default function Consumption() {
  const { t } = useTranslation();
  const [isAboveSm] = useBreakPoints();
  const location = useLocation();
  const { generateLink, data } = useContext(AppContext);

  if (data.contracts.length === 1 && !isAboveSm) {
    const c = data.contracts[0];
    return <Redirect to={`${location.pathname}/${encodeInvNumber(c.contractNumber)}/${c.pdeNumber}`} />;
  }

  return (
    <>
      <ViewTitle>{t('nav.consumption')}</ViewTitle>
      <SimpleGrid columns={[1, 2]} templateColumns={[null, '1fr 2fr', '1fr 3fr']} p={4} gap={4}>
        <Box>
          <Hide above={'sm'}>
            <Box mb={2}>
              <BlueHeading>{t('consumption.title')}</BlueHeading>
              <GrayHeading>{t('consumption.subTitle')}</GrayHeading>
            </Box>
          </Hide>

          <ContractList contracts={data.contracts} baseUrl={generateLink('/consumption')} />
        </Box>

        <Show above={'sm'}>
          <Text fontSize={'3xl'} color={'gray.400'} p={10} textAlign={'center'}>
            Seleziona un contratto di fornitura per visualizzare informazioni sui consumi
          </Text>
        </Show>
      </SimpleGrid>
    </>
  );
}