import React, { useEffect, useState } from 'react';
import { ChakraProvider, Container, extendTheme, Flex, Spinner } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import SplashScreen from './views/SplashScreen';
import ScrollToTop from './ScrollToTop';
import { LANGUAGES, SERVICE_TYPES } from './constants';
import Layout from './Layout';
import LanguageSwitcher from './LanguageSwitcher';
import NotFound from './views/NotFound';
import { domainConfigs } from './domainConfigs';
import '@egjs/react-flicking/dist/flicking.css';
import { themeConfig } from './themeConfig';

export const AppContext = React.createContext({
  appState: {
    company: '',
    type: '',
    invoiceId: '',
    lang: '',
  },
  setup: {},
  data: {},
  content: {},
  IS_SERVICE_ELECTRICITY: false,
  IS_SERVICE_H2O: false,
  logEvent: () => null,
  generateLink: () => null,
});

export default function App() {
  const { REACT_APP_BACKEND_URL } = process.env;
  const domainCfg = domainConfigs[window.location.host];

  if (!domainCfg) {
    throw new Error(`Missing cfg for domain ${window.location.host}`);
  }

  const [appLoaded, setAppLoaded] = useState(false);
  const [appState, setAppState] = useState({
    company: domainCfg.company,
    type: domainCfg.type,
    invoiceId: window.location.pathname !== '/' ? window.location.pathname.split('/')[3] : null,
    lang: window.localStorage.getItem('lang') || 'it',
  });
  const [setup, setSetup] = useState({});
  const [data, setData] = useState({});
  const [content, setContent] = useState({});

  if (window.location.pathname === '/') {
    window.location.href = `/ib/${appState.type}/${'0'.repeat(64)}${window.location.search}`;
  }

  useEffect(() => {
    async function initApp() {
      const responses = await Promise.all([
        fetch(`/lang/${appState.lang}.json?v=4`),
        fetch(`${REACT_APP_BACKEND_URL}/data/${appState.company}/${appState.type}/setup`),
        fetch(`${REACT_APP_BACKEND_URL}/data/${appState.company}/${appState.type}/${appState.invoiceId.replaceAll('0', '') === '' ? '' : appState.invoiceId}`),
      ]);

      const translationsResponse = await responses[0].json();
      const setupResponse = await responses[1].json();
      const contentDataResponse = await responses[2].json();

      await initTranslation(appState.lang, translationsResponse);
      const { billHolder } = contentDataResponse[appState.type];
      setSetup(setupResponse['Company'][appState.type]);
      setData(billHolder.data);
      setContent(billHolder.content);
      setAppLoaded(true);
    }

    initApp();
  }, [REACT_APP_BACKEND_URL, appState.company, appState.type]);

  const serviceTypes = Object.values(SERVICE_TYPES);
  const theme = extendTheme(themeConfig);

  if (!appLoaded) {
    return (
      <ChakraProvider theme={theme}>
        <Flex h={'100vh'} justifyContent={'center'} alignItems={'center'}>
          <Spinner
            thickness='6px'
            speed='0.9s'
            emptyColor='gray.200'
            color='blue.400'
            size='xl'
          />
        </Flex>
      </ChakraProvider>
    );
  }

  return (
    <AppContext.Provider value={{
      appState,
      setAppState,
      setup,
      data,
      content,
      generateLink: (to) => `/ib/${appState.type}/${appState.invoiceId}${to}`,
      IS_SERVICE_H2O: appState.type === SERVICE_TYPES.H20,
      IS_SERVICE_GAS: appState.type === SERVICE_TYPES.GAS,
      IS_SERVICE_ELECTRICITY: appState.type === SERVICE_TYPES.ELECTRICITY,
      logEvent,
    }}>
      <ChakraProvider theme={theme}>
        <Container maxW={'100%'} m={'0 auto'} p={0} pos={'relative'}>
          <Router>
            <Switch>
              <Route path={`/:lang(${LANGUAGES.join('|')})`} exact component={LanguageSwitcher} />
              <Route path={`/`} exact component={SplashScreen} />
              <Route path={`/ib/:service(${serviceTypes.join('|')})/:invoiceId(\\w{64})`} exact component={SplashScreen} />
              <Route path={`/ib`} component={Layout} />
              <Route path='*' component={NotFound} />
            </Switch>
            <ScrollToTop />
          </Router>
        </Container>
      </ChakraProvider>
    </AppContext.Provider>
  );
}

async function logEvent(eventType, args) {
  const data = {
    ...args,
    eventType,
    eventDateTime: new Date().toISOString(),
    messageId: sessionStorage.getItem('messageId'),
    channel: sessionStorage.getItem('channel'),
  };
  console.log(data);
  return;

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/log/event`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  console.log(res);
}

async function initTranslation(lang, translations) {
  await i18n.use(initReactI18next).init({
    resources: {
      [lang]: { translation: translations },
    },
    lng: lang,
    // debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false,
    },
  });
}

