import { Link } from 'react-router-dom';
import { Button, Image, Spacer, Text } from '@chakra-ui/react';

export default function CTAButton({ image, to, children }) {
  return (
    <Button bg={'white'} as={Link} to={to} h={'60px'} w={'100%'} shadow={'md'} color={'blue.600'} textTransform={'uppercase'} justifyContent={'flex-start'} variant={'ghost'} _hover={{}} _focus={{}}>
      <Image src={image} h={'32px'} />
      <Text flex={1} p={4}>{children}</Text>
      <Spacer />
      <Image src={'/img/arrow-24-b-right@2x.webp'} h={'32px'} />
    </Button>
  );
}