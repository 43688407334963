import { Button, Image, Text } from '@chakra-ui/react';
import CardHeader from './CardHeader';
import CardBody from './CardBody';
import Card from './Card';
import { useContext } from 'react';
import { AppContext } from './App';
import { useTranslation } from 'react-i18next';

export default function ContactEmergencyBox({ children }) {
  const { content } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Card>
      <Button as={'a'} href={content.contactsPage.banner2.link || '#'} variant={'ghost'} h={'auto'} display={'block'} p={0} _hover={{}}>
        <CardHeader>
          <Text>
            {t('contact.box2.title1')} <br />
            {t('contact.box2.title2')}
          </Text>
        </CardHeader>
        <CardBody textAlign={'center'}>
          <Image mx={'auto'} h={'100px'} src={content.contactsPage.banner2.imageUrl || '/img/img-emergency@2x.webp'} />
          {children}
        </CardBody>
      </Button>
    </Card>
  );
}