import { Box, Flex, IconButton, Input, Select } from '@chakra-ui/react';
import { FaSave } from 'react-icons/fa';
import { useState } from 'react';
import ErrorMessage from './ErrorMessage';
import { isEmail, isPhoneNumber } from './utils';

export default function AddContactForm({ onSubmit }) {
  const [type, setType] = useState('');
  const [value, setValue] = useState('');
  const [isError, setIsError] = useState(false);
  const disabled = !type || !value;

  const onSave = () => {
    if ((type === 'email' && !isEmail(value)) ||
      (type === 'tel' && !isPhoneNumber(value))) {
      setIsError(true);
      return;
    }

    setType('');
    setValue('');
    setIsError(false);
    onSubmit(type, value);
  };

  return (
    <>
      <Flex>
        <Select value={type} onChange={(e) => setType(e.target.value)} placeholder='Tipologia' bg={'blue.400'} color={'white'}>
          <option value='tel'>Telefono</option>
          <option value='email'>E-mail</option>
        </Select>
        <Input borderColor={isError ? 'red.400' : undefined} mx={2} placeholder={'insersci'} value={value} onChange={(e) => setValue(e.target.value)} />
        <IconButton
          disabled={disabled}
          onClick={onSave}
          opacity={'1 !important'}
          bg={disabled ? 'gray.400' : 'green.400'}
          color={'white'}
          _hover={{}} _focus={{}}
          aria-label={'add contact'} icon={<FaSave />} />
      </Flex>
      <Box>
        <ErrorMessage show={isError}>Valore non corretto</ErrorMessage>
      </Box>
    </>
  );
}