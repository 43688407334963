import { Box } from '@chakra-ui/react';
import ItemHeader from '../ItemHeader';
import { useContext, useEffect } from 'react';
import { AppContext } from '../App';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { decodeInvNumber } from '../utils';
import InvoiceCard from '../InvoiceCard';


export default function Invoice() {
  const { generateLink, data, logEvent } = useContext(AppContext);
  const id = decodeInvNumber(useParams().id);
  const { t } = useTranslation();
  const bill = data.lastBills.find(x => x.number === id);

  useEffect(() => {
    logEvent({
      message: 'INVOICE_VIEW',
    });
  }, []);

  if (!bill) {
    return <Redirect to={generateLink('/invoices')} />;
  }

  return (
    <Box>
      <ItemHeader
        dir={'back'}
        title1={t('invoice.number')}
        title2={bill.number}
        to={generateLink('/invoices')} />

      <InvoiceCard bill={bill} />
    </Box>
  );
}