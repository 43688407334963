import Header from './Header';
import { Box } from '@chakra-ui/react';
import { Route, Switch } from 'react-router-dom';
import Profile from './views/Profile';
import Invoices from './views/Invoices';
import InvoiceItem from './views/InvoiceItem';
import InvoicePaymentSuccess from './views/InvoicePaymentSuccess';
import InvoicePaymentFailed from './views/InvoicePaymentFailed';
import Invoice from './views/Invoice';
import ConsumptionDetailEfficiency from './views/ConsumptionDetailEfficiency';
import ConsumptionDetailSustainability from './views/ConsumptionDetailSustainability';
import ConsumptionDetail from './views/ConsumptionDetail';
import Consumption from './views/Consumption';
import MeterReadingDetail from './views/MeterReadingDetail';
import MeterReading from './views/MeterReading';
import ContactsBranches from './views/ContactsBranches';
import Contacts from './views/Contacts';
import NotFound from './views/NotFound';
import Footer from './Footer';
import React from 'react';
import { SERVICE_TYPES } from './constants';

export default function Layout() {
  const serviceTypes = Object.values(SERVICE_TYPES);

  return (
    <>
      <Header />
      <Box h={'calc(100vh - 120px)'} overflow={'scroll'} bg={'gray.50'}>
        <Switch>
          <Route path={`/ib/:service(${serviceTypes.join('|')})/:invoiceId(\\w{64})/profile`} component={Profile} />
          <Route path={`/ib/:service(${serviceTypes.join('|')})/:invoiceId(\\w{64})/invoices`} component={Invoices} />
          <Route path={`/ib/:service(${serviceTypes.join('|')})/:invoiceId(\\w{64})/invoice/:id/:contractId/detail`} component={InvoiceItem} />
          <Route path={`/ib/:service(${serviceTypes.join('|')})/:invoiceId(\\w{64})/invoice/:id/success`} component={InvoicePaymentSuccess} />
          <Route path={`/ib/:service(${serviceTypes.join('|')})/:invoiceId(\\w{64})/invoice/:id/failed`} component={InvoicePaymentFailed} />
          <Route path={`/ib/:service(${serviceTypes.join('|')})/:invoiceId(\\w{64})/invoice/:id`} component={Invoice} />
          <Route path={`/ib/:service(${serviceTypes.join('|')})/:invoiceId(\\w{64})/consumption/:id/efficiency`} component={ConsumptionDetailEfficiency} />
          <Route path={`/ib/:service(${serviceTypes.join('|')})/:invoiceId(\\w{64})/consumption/:id/sustainability`} component={ConsumptionDetailSustainability} />
          <Route path={`/ib/:service(${serviceTypes.join('|')})/:invoiceId(\\w{64})/consumption/:id/:pde`} component={ConsumptionDetail} />
          <Route path={`/ib/:service(${serviceTypes.join('|')})/:invoiceId(\\w{64})/consumption`} component={Consumption} />
          <Route path={`/ib/:service(${serviceTypes.join('|')})/:invoiceId(\\w{64})/meter-reading/:id/:pde`} component={MeterReadingDetail} />
          <Route path={`/ib/:service(${serviceTypes.join('|')})/:invoiceId(\\w{64})/meter-reading`} component={MeterReading} />
          <Route path={`/ib/:service(${serviceTypes.join('|')})/:invoiceId(\\w{64})/contacts/branches`} component={ContactsBranches} />
          <Route path={`/ib/:service(${serviceTypes.join('|')})/:invoiceId(\\w{64})/contacts`} component={Contacts} />
          <Route path='*' component={NotFound} />
        </Switch>
      </Box>
      <Footer />
    </>
  );
}