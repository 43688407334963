import { Flex } from '@chakra-ui/react';

export default function CardBody({ children, ...rest }) {
  return (
    <Flex
      borderBottom={12}
      p={4}
      flex={1}
      flexDirection={'column'}
      justifyContent={'space-between'}
      {...rest}>
      {children}
    </Flex>
  );
}