import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function ItemHeader({ title1, title2, to, dir = 'to', icon = true }) {
  return (
    <Flex as={to && dir === 'to' ? Link : Box} to={to && dir === 'to' ? to : undefined} color={'white'} bg={'blue.600'} p={2} alignItems={'center'}>
      {dir === 'back' && <Button as={Link} to={to} p={1} mr={1} variant={'unstyled'} _hover={{}} _focus={{}}>
        <Box w={'32px'} h={'32px'}><Image src={'/img/arrow-24-w-left@2x.webp'} /></Box>
      </Button>}
      {icon && <Box w={'40px'} h={'40px'} mx={2}><Image src={'/img/ic-b-light@2x.webp'} /></Box>}
      <Box flex={1}>
        <Text textTransform={'uppercase'}>{title1}</Text>
        <Text fontWeight={'bold'} fontSize={'xl'}>{title2}</Text>
      </Box>
      {to && dir === 'to' && <Box w={'32px'} h={'32px'}><Image src={'/img/arrow-24-w-right@2x.webp'} /></Box>}
    </Flex>
  );
}