import { Box, Button, Flex, Image, Show, SimpleGrid, Text } from '@chakra-ui/react';
import InvoiceStats from './InvoiceStats';
import Card from './Card';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from './App';
import { useBreakPoints } from './utils';
import { useHistory } from 'react-router-dom';
import InvoiceItemTables from './InvoiceItemTables';

export default function InvoiceCard({ bill }) {
  const { IS_SERVICE_H2O } = useContext(AppContext);
  const { t } = useTranslation();
  const [isAboveSm] = useBreakPoints();
  let history = useHistory();
  const [currentContractIdx, setCurrentContractIdx] = useState(0);
  const [currentContract, setCurrentContract] = useState(bill.lastBillSplit[0]);

  const nextContract = () => {
    setCurrentContractIdx(currentContractIdx + 1);
    setCurrentContract(bill.lastBillSplit[currentContractIdx + 1]);
  };

  const prevContract = () => {
    setCurrentContractIdx(currentContractIdx - 1);
    setCurrentContract(bill.lastBillSplit[currentContractIdx - 1]);
  };

  const onDetailClick = (e) => {
    if (!isAboveSm) {
      return history.push(window.location.pathname + '/' + currentContract.contractNumber + '/detail');
    }
  };

  return (
    <Card m={[4, 0]} justifyContent={'flex-start'}>
      {bill.multiContract && <Flex flexGrow={0} position={'relative'} color={'white'} borderTopRadius={12} bg={'blue.600'} py={2} px={4} alignItems={'center'}>
        <Box w={'32px'}>
          {currentContractIdx > 0 && <Button variant={'unstyled'} onClick={prevContract} _focus={{}}>
            <Image src={'/img/arrow-24-w-left@2x.webp'} w={'100%'} />
          </Button>}
        </Box>
        {/*<Image src={'/img/ic-b-light@2x.webp'} h={'40px'} mx={4} />*/}
        <Box flex={1} textAlign={'center'}>
          <Text textTransform={'uppercase'}>{bill.contractType}</Text>
          <Text fontWeight={'bold'} fontSize={'xl'}>{t('contract.title')} {currentContract.contractNumber} {IS_SERVICE_H2O ? ` - PDE ${currentContract.pdeNumber}` : ''}</Text>
        </Box>
        <Box w={'32px'}>
          {currentContractIdx < bill.lastBillSplit.length - 1 && <Button variant={'unstyled'} onClick={nextContract} _focus={{}}>
            <Image src={'/img/arrow-24-w-right@2x.webp'} w={'100%'} />
          </Button>}
        </Box>
      </Flex>}

      <SimpleGrid columns={[1, 2]} templateColumns={[null, '1fr 3fr']}>
        <Box minW={'300px'} mx={4}>
          <InvoiceStats
            onItemClick={onDetailClick}
            bill={bill}
            total={currentContract.totalAmount}
            table={bill.multiContract ? currentContract.contractTable.rows : bill.splitTable.rows}
          />
        </Box>
        <Show above={'sm'}>
          <InvoiceItemTables tables={currentContract.splitTable} />
        </Show>
      </SimpleGrid>
    </Card>
  );
}