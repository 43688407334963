import CardHeader from './CardHeader';
import { Image, Link as A, Text } from '@chakra-ui/react';
import CardBody from './CardBody';
import Card from './Card';
import { useContext } from 'react';
import { AppContext } from './App';
import { useTranslation } from 'react-i18next';

export default function ContactAssistanceBox({ openingHours, children }) {
  const { setup } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader>
        <Text>{t('contact.box3.title')}</Text>
      </CardHeader>
      <CardBody textAlign={'center'}>
        <Image mx={'auto'} h={'100px'} src={'/img/ic-assistance@2x.webp'} />
        <Text mt={2} fontWeight={'bold'}>
          {setup.tollFreeNumber} {t('contact.box3.text1')} <br /> {t('contact.box3.text2')} {openingHours.map(row => `${row.name}: ${row.openingHours}`).join(', ')}
        </Text>
        <Text>
          Email: <A href={`mailto:${setup.customerServiceEmail}`} color={'green.400'}>{setup.customerServiceEmail}</A>
        </Text>
        {children}
      </CardBody>
    </Card>
  );
}