import Card from './Card';
import CardBody from './CardBody';
import BlueHeading from './BlueHeading';
import { Divider, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AppContext } from './App';
import { encodeInvNumber } from './utils';
import ItemHeader from './ItemHeader';

export default function ContractList({ contracts, baseUrl }) {
  const { t } = useTranslation();
  const { IS_SERVICE_H2O } = useContext(AppContext);

  if (!contracts || contracts.length === 0) {
    return null;
  }

  return (
    contracts.map((contract, i) => (
      <Card key={`${contract.contractNumber}-${contract.pdeNumber || i}`}>
        <ItemHeader
          title1={contract.contractType}
          title2={IS_SERVICE_H2O ? `${t('contract.title')} ${contract.contractNumber} - PDE ${contract.pdeNumber}` : `${t('contract.title')} ${contract.contractNumber}`}
          to={baseUrl ? `${baseUrl}/${encodeInvNumber(contract.contractNumber)}/${contract.pdeNumber}` : undefined} />
        <CardBody fontWeight={'bold'}>
          <BlueHeading fontSize={'md'}>{t('contract.address')}</BlueHeading>
          <Text>{contract.contractAddress.street}, {contract.contractAddress.number}</Text>
          <Text>{contract.contractAddress.postalCode} {contract.contractAddress.city} ({contract.contractAddress.province})</Text>
          <Divider color={'gray.200'} my={2} />
          {contract.tariffType && <Text>Tipologia d’uso: {contract.tariffType}</Text>}
          {!!contract.familyMembers && <Text>Numero componenti nucleo familiare: {contract.familyMembers}</Text>}
        </CardBody>
      </Card>
    ))
  );
}