import { Box, Flex, Image, SimpleGrid, Spacer, Text } from '@chakra-ui/react';
import { Chart } from 'react-google-charts';
import { formatAmount } from './utils';
import { useTranslation } from 'react-i18next';

const InvoiceItem = ({ name, amount, color, compact, onClick }) => (
  <Flex
    alignItems={'center'} variant={'ghost'} borderBottom={compact ? 0 : 1}
    borderStyle={'solid'} borderColor={'gray.200'} py={compact ? 1 : 3}>
    <Text borderLeft={compact ? 20 : 6} borderStyle={'solid'} borderColor={color} pl={2}>{name}</Text>
    <Spacer />
    <Text fontWeight={'bold'} whiteSpace={'nowrap'} mr={2}>{formatAmount(amount)} &euro;</Text>
    {!!onClick && <Image src={'/img/arrow-20-bb-right@2x.webp'} h={'16px'} />}
  </Flex>
);

export default function InvoiceStats({ bill, total, table, onItemClick, compact = false }) {
  const { t } = useTranslation();

  let graphData = table.map(x => [x.column2, +x.column3.replace(',', '.')]);
  graphData = graphData.filter(x => x[1] > 0);

  graphData.unshift(['Consumption', 'Euro']);

  return (
    <Box>
      <Text fontWeight={'bold'} textAlign={'center'} color={'blue.400'} fontSize={'xl'} my={4}>
        {t('invoice.item.title')}
      </Text>

      <SimpleGrid columns={compact ? 2 : 1} gap={4}>
        <Box position={'relative'} minH={'100px'} alignSelf={'start'}>
          {graphData.length > 1 && <Chart
            width={'100%'}
            height={'350px'}
            chartType='PieChart'
            loader={<div>Loading Chart</div>}
            data={graphData}
            options={{
              legend: 'none',
              pieSliceText: 'none',
              pieStartAngle: 100,
              backgroundColor: 'transparent',
              pieHole: 0.5,
              colors: table.filter(x => +x.column3.replace(',', '.') > 0).map(x => x.column1.toLowerCase()),
              chartArea: { width: '100%', height: '90%' },
            }}
          />}
          <Text whiteSpace={'nowrap'} position={'absolute'} top={'50%'} left={'50%'} fontSize={['3xl', '2xl']} fontWeight={'bold'} transform={'translate(-50%, -50%)'}>
            {bill.currencySymbol} {formatAmount(total)}
          </Text>
        </Box>

        <Box
          // to={contract && `${location.pathname}/${contract.contractNumber}/detail`}
          onClick={onItemClick}
          bg={'white'}>
          {table.map(row => (<InvoiceItem
            key={row.column2}
            name={row.column2}
            amount={row.column3}
            color={row.column1}
            compact={compact} />))}
        </Box>
      </SimpleGrid>
    </Box>
  );
}