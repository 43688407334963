import { Box } from '@chakra-ui/react';
import { getOpeningHours } from '../utils';
import { useContext } from 'react';
import { AppContext } from '../App';
import EgeaContactsLayout from '../layout/egea/EgeaContactsLayout';
import ViacquaContactsLayout from '../layout/viacqua/ViacquaContactsLayout';
import { COMPANIES } from '../constants';

export default function Contacts() {
  const { appState, setup, IS_SERVICE_H2O, IS_SERVICE_ELECTRICITY } = useContext(AppContext);
  const openingHours = getOpeningHours(setup.tollFreeNumberOpeningHours);

  return (
    <Box>
      {IS_SERVICE_ELECTRICITY && appState.company === COMPANIES.EGEA && <EgeaContactsLayout openingHours={openingHours} />}
      {IS_SERVICE_H2O && appState.company === COMPANIES.VIACQUA && <ViacquaContactsLayout openingHours={openingHours} />}
    </Box>
  );
}