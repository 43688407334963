import Card from './Card';
import CardHeader from './CardHeader';
import CardBody from './CardBody';
import { Box, Image, Spacer, Text } from '@chakra-ui/react';
import BlueHeading from './BlueHeading';
import CardGreenButton from './CardGreenButton';
import { useTranslation } from 'react-i18next';

export default function ContactBranchBox({ branch, openingHours }) {
  const { t } = useTranslation();

  return (
    <Card mb={0}>
      <CardHeader>{branch.branchCity} ({branch.branchProvince})</CardHeader>
      <CardBody p={0}>
        <Box p={4}>
          <BlueHeading fontSize={'sm'}>{t('contact.branches.address')}</BlueHeading>
          <Text>{branch.branchStreet} {branch.branchNumber}</Text>
          <BlueHeading fontSize={'sm'}>{t('contact.branches.openingHours')}</BlueHeading>
          {openingHours.map(row => <Text key={row.name}>{row.name}: {row.openingHours}</Text>)}
        </Box>

        <CardGreenButton d={'flex'} as={'a'} href={`https://www.google.com/maps/search/?api=1&query=${branch.branchLatitude}, ${branch.branchLongitude}`}>
          <Text ml={4}>{t('contact.branches.map')}</Text>
          <Spacer />
          <Image h={'16px'} src={'/img/arrow-20-w-right@2x.webp'} mr={4} />
        </CardGreenButton>
      </CardBody>
    </Card>
  );
}