import { Box, Button, Flex, Hide, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Show, SimpleGrid, Spinner, Table, Tbody, Td, Text, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import { encodeInvNumber, formatDate, slugify } from './utils';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from './App';
import InvoiceStats from './InvoiceStats';

const errorToast = (toast, message) => toast({
  title: 'Error',
  description: message,
  status: 'error',
  duration: 10000,
});

export default function InvoiceData({ bill, user }) {
  const { setup } = useContext(AppContext);
  const { t } = useTranslation();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [amountValue, amountDigits] = bill.amount.toString().split(',');
  const [paymentLoading, setPaymentLoading] = useState(false);
  const pdeNumbers = user.contracts.map(x => x.pdeNumber).join(', ');

  const memo = {
    name: `Scadenza bolletta VIACQUA n. ${bill.number} di ${bill.currencySymbol} ${bill.amount}`,
    date: new Date(bill.dueDate),
    notes: `Scadenza bolletta VIACQUA – SERVIZIO IDRICO ${bill.number} del ${formatDate(bill.issueDate)} di ${bill.currencySymbol} ${bill.amount} - Codice Cliente ${user.clientID} - Fornitura ${pdeNumbers}`,
  };

  const handlePay = async () => {
    setPaymentLoading(true);
    const { REACT_APP_AXEPTA_BASE_URL, REACT_APP_AXEPTA_LICENSE_KEY_EASY_CHECKOUT } = process.env;

    const params = new URLSearchParams({
      amount: bill.amount.toString().replace(',', '.'),
      name: user.name || '',
      surname: user.surname || '',
      email: user.email || '',
      phoneNumber: user.phoneNumber || '',
      successUrl: `${window.location.origin}/invoice/${encodeInvNumber(bill.number)}/success`,
      failureUrl: `${window.location.origin}/invoice/${encodeInvNumber(bill.number)}/failed`,
    });

    console.log(window.location.origin);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/payment/axepta/init-payment/${slugify(bill.number)}?${params.toString()}`);
      const data = await response.json();

      if (data.code !== 200) {
        console.error(data);
        errorToast(toast, `${data.code} - ${data.message}`);
      } else {
        window.location.href = `${REACT_APP_AXEPTA_BASE_URL}/circuits/${data.paymentID}/${REACT_APP_AXEPTA_LICENSE_KEY_EASY_CHECKOUT}`;
      }
    } catch (e) {
      errorToast(toast, e.message);
    } finally {
      setPaymentLoading(false);
    }
  };

  return (
    <>
      <SimpleGrid columns={[1, bill.multiContract ? 2 : 1, 1]}>
        <Box>
          <Box p={4}>
            <Text fontWeight={'bold'} fontSize={'3xl'}>{bill.currencySymbol} {amountValue},<small style={{ fontSize: '60%' }}>{amountDigits}</small></Text>
            <Text textTransform={'uppercase'} color={'green.400'} fontWeight={'bold'}>{t('invoice.dueDate')} {formatDate(bill.dueDate)}</Text>
          </Box>

          <Table variant={'simple'}>
            <Tbody>
              <Tr>
                <Td px={4} py={2}>
                  <Text color={'blue.600'} textTransform={'uppercase'} fontWeight={'bold'} fontSize={'sm'}>{t('profile.clientCode')}</Text>
                  <Text>{user.clientID}</Text>
                </Td>
                <Td px={4} py={2}>
                  <Text color={'blue.600'} textTransform={'uppercase'} fontWeight={'bold'} fontSize={'sm'}>{t('meterReading.table.type')}</Text>
                  <Text>{bill.type}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td px={4} py={2}>
                  <Text color={'blue.600'} textTransform={'uppercase'} fontWeight={'bold'} fontSize={'sm'}>{t('invoice.issueDate')}</Text>
                  <Text>{formatDate(bill.issueDate)}</Text>
                </Td>
                <Td px={4} py={2}>
                  <Text color={'blue.600'} textTransform={'uppercase'} fontWeight={'bold'} fontSize={'sm'}>{t('invoice.period')}</Text>
                  <Text>
                    {formatDate(bill.startPeriod)} - <br />
                    {formatDate(bill.endPeriod)}
                  </Text>
                </Td>
              </Tr>
              <Tr>
                <Td px={4} py={2} colSpan={2}>
                  <Text color={'blue.600'} textTransform={'uppercase'} fontWeight={'bold'} fontSize={'sm'}>{t('invoice.paymentMethod')}</Text>
                  <Text>{bill.defaultPaymentMethod}</Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>

        {bill.multiContract && <Show above={'sm'}>
          <Hide above={'md'}>
            <Box borderLeft={1} borderStyle={'solid'} borderColor={'gray.200'} p={4} pt={0}>
              <InvoiceStats bill={bill} total={bill.amount} table={bill.splitTable.rows} compact />
            </Box>
          </Hide>
        </Show>}
      </SimpleGrid>

      <Flex borderTopWidth={[0, 1, 0]} borderBottomWidth={[0, 0, 1]} borderStyle={'solid'} borderColor={'gray.200'} p={2} flex={1} justifyContent={'space-around'}>
        {!window.invoicePaid && <Button d={'flex'} flexDir={'column'} h={'100%'} isLoading={paymentLoading} spinner={<Spinner size='lg' />} loadingText={'loading ...'} onClick={handlePay} variant={'ghost'}>
          <Image m={'0 auto'} h={'32px'} src={'/img/ic-payment@2x.webp'} />
          <Text fontSize={'sm'} color={'blue.600'} textTransform={'uppercase'}>{t('invoice.pay')}</Text>
        </Button>}

        <Button d={'flex'} flexDir={'column'} h={'100%'} as={'a'} href={`/files/invoice-${setup.name.toLowerCase()}.pdf`} download={slugify(bill.number) + '.pdf'} variant={'ghost'}>
          <Image m={'0 auto'} h={'32px'} src={'/img/ic-download@2x.webp'} />
          <Text fontSize={'sm'} color={'blue.600'} textTransform={'uppercase'}>{t('invoice.download')}</Text>
        </Button>

        <Button d={'flex'} flexDir={'column'} h={'100%'} onClick={onOpen} variant={'ghost'}>
          <Image m={'0 auto'} h={'32px'} src={'/img/ic-memo@2x.webp'} />
          <Text fontSize={'sm'} color={'blue.600'} textTransform={'uppercase'}>{t('invoice.calendar')}</Text>
        </Button>
      </Flex>

      {bill.multiContract && <Show above={'md'}>
        <InvoiceStats bill={bill} total={bill.amount} table={bill.splitTable.rows} />
      </Show>}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('invoice.calendar')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir={'column'} justifyContent={'center'}>
              <Button as={'a'} target={'_blank'} href={`http://www.google.com/calendar/render?${new URLSearchParams({
                action: 'TEMPLATE',
                dates: `${memo.date.toISOString().replace(/-|:|\.\d\d\d/g, '')}/${new Date(memo.date.getTime() + 86400000).toISOString().replace(/-|:|\.\d\d\d/g, '')}`,
                text: memo.name,
                details: memo.notes,
              }).toString()}`} my={2}>
                Google calendar
              </Button>
              {/*<Button my={2}>Apple calendar</Button>*/}
              {/*<Button my={2}>Outlook calendar</Button>*/}
              {/*<Button my={2}>Yahoo calendar</Button>*/}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}