import { Button, Flex, Image, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../App';

export default function InvoicePaymentSuccess() {
  const { t } = useTranslation();
  const { generateLink } = useContext(AppContext);

  useEffect(() => {
    window.invoicePaid = true;
  }, []);

  return (
    <Flex direction={'column'} alignItems={'center'} textAlign={'center'}>
      <Text fontSize={'3xl'} color={'blue.600'} fontWeight={'bold'} mt={'20%'}>
        {t('invoice.payment.paid.text1')}
      </Text>
      <Image src={'/img/img-confirm@2x.png'} my={10} h={'200px'} />
      <Text fontSize={'xl'}>{t('invoice.payment.paid.text2')}</Text>

      <Button m={4} variant={'unstyled'} as={Link} to={generateLink('/invoices')} color={'blue.600'} textDecoration={'underline'} textTransform={'uppercase'}>
        {t('button.close')}
      </Button>
    </Flex>
  );
}