import { Box, Hide, Show, SimpleGrid } from '@chakra-ui/react';
import ItemHeader from '../ItemHeader';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../App';
import { useTranslation } from 'react-i18next';
import ViacquaConsumptionDetailLayout from '../layout/viacqua/ViacquaConsumptionDetailLayout';
import EgeaConsumptionDetailLayout from '../layout/egea/EgeaConsumptionDetailLayout';
import { COMPANIES } from '../constants';
import ViewTitle from '../ViewTitle';
import BlueHeading from '../BlueHeading';
import GrayHeading from '../GrayHeading';
import ContractList from '../ContractList';
import { decodeInvNumber } from '../utils';

export default function ConsumptionDetail() {
  const { id, pde } = useParams();
  const { t } = useTranslation();
  const { appState, data, generateLink, IS_SERVICE_H2O, IS_SERVICE_ELECTRICITY } = useContext(AppContext);
  const contract = data.contracts.find(x => x.contractNumber === decodeInvNumber(id) && x.pdeNumber === pde);

  return (
    <>
      <ViewTitle>{t('nav.consumption')}</ViewTitle>
      <SimpleGrid columns={[1, 2]} templateColumns={[null, '1fr 2fr', '1fr 3fr']} p={[0, 4]} gap={4}>
        <Show above={'sm'}>
          <Box>
            <Hide above={'sm'}>
              <Box mb={2}>
                <BlueHeading>{t('consumption.title')}</BlueHeading>
                <GrayHeading>{t('consumption.subTitle')}</GrayHeading>
              </Box>
            </Hide>

            <ContractList contracts={data.contracts} baseUrl={generateLink('/consumption')} />
          </Box>
        </Show>

        <Box>
          <Hide above={'sm'}>
            <ItemHeader
              dir={'back'}
              title1={t('consumption.detail.title')}
              title2={`${IS_SERVICE_ELECTRICITY ? t('contract.title') : 'Punto di Erogazione'} ${contract.contractNumber}`}
              to={generateLink(data.contracts.length > 1 ? `/consumption` : `/profile`)} />
          </Hide>

          <Box p={[4, 0]}>
            {IS_SERVICE_H2O && appState.company === COMPANIES.VIACQUA && <ViacquaConsumptionDetailLayout contract={contract} />}
            {IS_SERVICE_ELECTRICITY && appState.company === COMPANIES.EGEA && <EgeaConsumptionDetailLayout contract={contract} />}
          </Box>
        </Box>
      </SimpleGrid>
    </>
  );
}