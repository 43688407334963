import { Box } from '@chakra-ui/react';

export default function CardHeader({ children, ...rest }) {
  return (
    <Box
      textAlign={'left'}
      position={'relative'}
      color={'white'}
      borderTopRadius={12}
      bg={'blue.600'}
      px={4}
      py={2}
      textTransform={'uppercase'}
      fontWeight={'bold'}
      lineHeight={1.2}
      {...rest}>
      {children}
    </Box>
  );
}