import { themeConfig } from './themeConfig';

export function isEmail(value) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
}

export function isPhoneNumber(value) {
  return /^\+?[\d\s]+\/?[\d\s]+$/.test(value);
}

export function formatAmount(amount, fractionDigits = 2) {
  if (typeof amount === 'string') {
    amount = +amount.replace(',', '.');
  }
  const options = {
    style: 'decimal',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  };

  return new Intl.NumberFormat('it-IT', options).format(amount);
}

export function formatDate(date) {
  return new Date(date).toLocaleDateString('it-IT', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
}

export function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;';
  var to = 'AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------';

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, ''); // trim - from end of text

  return str;
}

export function useBreakPoints() {
  return [
    window.innerWidth > +themeConfig.breakpoints.sm.replace('px', ''),
    window.innerWidth > +themeConfig.breakpoints.md.replace('px', ''),
  ];
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function encodeInvNumber(invNumber) {
  return invNumber.replaceAll('/', '|');
}

export function decodeInvNumber(invNumber) {
  return invNumber.replaceAll('|', '/');
}

export function getOpeningHours(openingHours) {
  let rows = [];
  const days = [
    { name: 'monday', translation: 'Lun' },
    { name: 'tuesday', translation: 'Mar' },
    { name: 'wednesday', translation: 'Mer' },
    { name: 'thursday', translation: 'Gio' },
    { name: 'friday', translation: 'Ven' },
    { name: 'saturday', translation: 'Sab' },
    { name: 'sunday', translation: 'Dom' },
  ];

  for (let i = 0; i < 7; i++) {
    const day = days[i];
    const openingHour = openingHours[0][day.name][0].openingHour?.split('-')[0].split(':')[0];
    const closingHour = openingHours[0][day.name][0].closingHour?.split('-')[0].split(':')[0];
    if (!openingHour && !closingHour) {
      continue;
    }

    if (
      i > 0 &&
      openingHour === openingHours[0][days[i - 1].name][0].openingHour?.split('-')[0].split(':')[0] &&
      closingHour === openingHours[0][days[i - 1].name][0].closingHour?.split('-')[0].split(':')[0]
    ) {
      const last = rows[rows.length - 1];
      last.name += ', ' + day.translation;
    } else {
      rows.push({
        name: days[i].translation, openingHours: `${openingHour}-${closingHour}`,
      });
    }
  }

  return rows;
}