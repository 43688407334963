import { Box, Button, Flex, Hide, HStack, IconButton, Image, Show, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AppContext } from './App';

const NAV_ITEMS = [
  { as: Link, label: 'nav.tutorial', to: '#', icon: '/img/ic-nav-tutorial@2x.png' },
  { as: Link, label: 'nav.invite', to: '#', icon: '/img/ic-nav-invite@2x.png' },
  { as: Link, label: 'nav.survey', to: '#', icon: '/img/ic-nav-survey@2x.png' },
  { as: Link, label: 'nav.branches', to: '/contacts/branches', icon: '/img/ic-nav-finder@2x.png' },
];

const Logo = ({ src }) => (<Image p={2} src={src} alt='logo' />);

const SideNav = ({ logo, items, onClose }) => {
  return (
    <Box w={'70%'} h={'100%'} bg={'white'}>
      <Flex
        h={'60px'}
        justifyContent={'space-between'}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={'gray.200'}>

        <Logo src={logo} />

        <IconButton
          h={'100%'}
          onClick={onClose}
          icon={<Image src='/img/btn-menu-close@2x.png' h={'100%'} alt='' />}
          variant={'ghost'}
          aria-label={'Close navigation'}
        />
      </Flex>

      <Stack mt={6}>
        {items.map((navItem, i) => (
          <NavItem key={navItem.label} {...navItem} onClick={onClose} />
        ))}
      </Stack>
    </Box>
  );
};

const NavItem = ({ label, icon, onClick, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Flex
        {...rest}
        p={3}
        align={'center'}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={'gray.200'}
        onClick={onClick}>
        <Image h={'28px'} mr={4} src={icon} />
        <Text
          fontWeight={600}
          color={'gray.600'}>
          {t(label)}
        </Text>
      </Flex>
    </Box>
  );
};

export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setup } = useContext(AppContext);
  const logoPath = `/img/logo-${setup.name.toLowerCase()}.webp`;
  const items = setup.name === 'EGEA' ? NAV_ITEMS : setup.dropDownMenu.map((x, i) => ({
    as: 'a',
    label: x.label,
    href: x.url,
    target: '_blank',
    icon: NAV_ITEMS[i]?.icon || '/img/ic-nav-tutorial@2x.png',
  }));

  return (
    <Box>
      <Flex
        justifyContent={'space-between'}
        height={'60px'}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={'gray.200'}>

        <Logo src={logoPath} />

        <Show above={'md'}>
          <HStack>
            {items.map((navItem, i) => <Button key={navItem.label} {...navItem} variant={'ghost'} px={2} color={'blue.400'} textTransform={'uppercase'} fontWeight={'bold'}>
              {navItem.label}
            </Button>)}
          </HStack>
        </Show>

        <Hide above={'md'}>
          <IconButton
            h={'100%'}
            onClick={onOpen}
            icon={<Image h={'100%'} src='/img/btn-menu@2x.webp' alt='' />}
            aria-label={'Toggle Navigation'}
          />
        </Hide>
      </Flex>

      <Box zIndex={1000} display={isOpen ? 'block' : 'none'} pos={'absolute'} top={0} left={0} bottom={0} right={0} bg={'rgba(0,0,0,0.7)'}>
        <SideNav logo={logoPath} items={items} onClose={onClose} />
      </Box>
    </Box>
  );
}