import { Box, Hide, Show, SimpleGrid, Text } from '@chakra-ui/react';
import Card from '../Card';
import CardBody from '../CardBody';
import InvoiceData from '../InvoiceData';
import InvoiceStats from '../InvoiceStats';
import { useContext } from 'react';
import { AppContext } from '../App';
import { useTranslation } from 'react-i18next';
import { encodeInvNumber, useBreakPoints } from '../utils';
import ItemHeader from '../ItemHeader';
import SuggestionBox from '../SuggestionBox';
import ViewTitle from '../ViewTitle';
import InvoiceCard from '../InvoiceCard';

export default function Invoices() {
  const { t } = useTranslation();
  const { generateLink, data, content, IS_SERVICE_ELECTRICITY } = useContext(AppContext);
  const [isAboveSm] = useBreakPoints();

  return (
    <Box p={[4, 0]}>
      <ViewTitle>{t('nav.invoices')}</ViewTitle>
      {data.lastBills.map(bill => (
        <SimpleGrid key={bill.number} columns={[1, 1, 2]} templateColumns={[null, null, '2fr 5fr']} p={[0, 4]} gap={4}>
          <Box>
            <Card key={bill.number}>
              <ItemHeader
                title1={t('invoice.number')}
                title2={bill.number}
                to={bill.multiContract && !isAboveSm ? generateLink(`/invoice/${encodeInvNumber(bill.number)}`) : undefined}
              />
              <CardBody p={0}>
                <InvoiceData bill={bill} user={data} />
              </CardBody>
            </Card>

            {IS_SERVICE_ELECTRICITY && <SuggestionBox
              image={content.billPage.banner1.imageUrl}
              href={content.billPage.banner1.link || '#'}
              title={t('invoice.energyBox.title')}>
              <Text>{t('invoice.energyBox.text1')}</Text>
              <Text>{t('invoice.energyBox.text2')}</Text>
            </SuggestionBox>}

            <Hide above={'sm'}>
              <InvoiceStats bill={bill} total={bill.amount} table={bill.splitTable.rows} />
            </Hide>
          </Box>

          <Show above={'sm'}>
            <InvoiceCard bill={bill} />
          </Show>
        </SimpleGrid>
      ))}
    </Box>
  );
}