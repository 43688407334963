import { Box, Image, Input, InputGroup, InputLeftElement, SimpleGrid } from '@chakra-ui/react';
import ItemHeader from '../ItemHeader';
import { useContext, useState } from 'react';
import { AppContext } from '../App';
import { getOpeningHours } from '../utils';
import { useTranslation } from 'react-i18next';
import ContactBranchBox from '../ContactBranchBox';
import BlueHeading from '../BlueHeading';

const getKey = branch => `${branch.branchCity}-${branch.branchStreet}-${branch.branchNumber}`;

export default function ContactsBranches({ isPage = true }) {
  const { t } = useTranslation();
  const { generateLink, setup } = useContext(AppContext);
  const [branches, setBranches] = useState(setup.branches);

  const openingHours = setup.branches.reduce((acc, branch) => {
    acc[getKey(branch)] = getOpeningHours(branch.branchOpeningHours);
    return acc;
  }, {});

  const onChange = (e) => {
    const { value } = e.target;
    setBranches(setup.branches.filter(b => b.branchCity.toLowerCase().includes(value.toLowerCase())));
  };

  return (
    <Box>
      {isPage && <ItemHeader dir={'back'} title1={t('contact.title')} title2={t('contact.branches.title')} to={generateLink('/contacts')} />}

      <Box p={4} pt={isPage ? 4 : 0}>
        {!isPage && <BlueHeading mb={4}>{t('contact.branches.title')}</BlueHeading>}

        <InputGroup mb={4}>
          <InputLeftElement
            pointerEvents='none'
            children={<Image h={'16px'} src={'/img/ic-search@2x.webp'} />}
          />
          <Input bg={'white'} placeholder={t('contact.branches.input')} onChange={onChange} />
        </InputGroup>

        <SimpleGrid columns={[1, 2, 3]} gap={4}>
          {branches.map(branch => <ContactBranchBox key={getKey(branch)} branch={branch} openingHours={openingHours[getKey(branch)]} />)}
        </SimpleGrid>
      </Box>
    </Box>
  );
}