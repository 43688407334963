import { Box, Button, Hide, HStack, Image, Input, InputGroup, InputRightElement, Select, Show, SimpleGrid, Table, Tbody, Td, Text, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import Card from '../Card';
import CardBody from '../CardBody';
import ItemHeader from '../ItemHeader';
import BlueHeading from '../BlueHeading';
import CardGreenButton from '../CardGreenButton';
import { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { decodeInvNumber, formatDate } from '../utils';
import { AppContext } from '../App';
import { useTranslation } from 'react-i18next';
import ViewTitle from '../ViewTitle';
import GrayHeading from '../GrayHeading';
import ContractList from '../ContractList';
import MeterReadingValueSubmittedModal from '../MeterReadingValueSubmittedModal';
import MeterReadingContactModal from '../MeterReadingContactModal';
import ErrorMessage from '../ErrorMessage';

export default function MeterReadingDetail() {
  let inputRef = useRef();
  const { id, pde } = useParams();
  const { t } = useTranslation();
  const { isOpen: meterReadingValueIsOpen, onOpen: meterReadingValueOnOpen, onClose: meterReadingValueOnClose } = useDisclosure();
  const { isOpen: meterReadingContactIsOpen, onOpen: meterReadingContactOnOpen, onClose: meterReadingContactOnClose } = useDisclosure();
  const { generateLink, data, content } = useContext(AppContext);
  const contract = data.contracts.find(x => x.contractNumber === decodeInvNumber(id) && x.pdeNumber === pde);
  const allReadings = contract.contractMetering.readingsTable.rows;
  const years = [...new Set(allReadings.map(x => x.column1.split('-')[0]))];
  const [year, setYear] = useState(years[0]);
  const readings = [...allReadings].filter(x => x.column1.startsWith(year));
  const [isError, setIsError] = useState(false);

  const onYearChange = (e) => {
    setYear(e.target.value);
  };

  const onSubmit = () => {
    const val = inputRef.current.value;
    const max = Math.max(0, ...readings.map(x => +x.column3));
    if (!val || val < max) {
      setIsError(true);
      return;
    }
    setIsError(false);
    inputRef.current.value = '';
    meterReadingValueOnOpen();
  };

  return (
    <>
      <ViewTitle>{t('nav.meterReading')}</ViewTitle>
      <SimpleGrid columns={[1, 2]} templateColumns={[null, '1fr 2fr', '1fr 3fr']} p={[0, 4]} gap={4}>
        <Show above={'sm'}>
          <Box>
            <Hide above={'sm'}>
              <Box mb={2}>
                <BlueHeading>{t('meterReading.title')}</BlueHeading>
                <GrayHeading>{t('meterReading.subTitle')}</GrayHeading>
              </Box>
            </Hide>

            <ContractList contracts={data.contracts} baseUrl={generateLink('/meter-reading')} />
          </Box>
        </Show>

        <Box>
          <Hide above={'sm'}>
            <ItemHeader
              dir={'back'}
              title1={t('meterReading.detail.title')}
              title2={`${t('contract.title')} ${contract.contractNumber}`}
              to={generateLink(data.contracts.length > 1 ? '/meter-reading' : '/profile')} />
          </Hide>

          <Box p={[4, 0]}>
            <BlueHeading mb={4}>
              {t('nav.consumption')} - {t('contract.title')} {contract.contractNumber}
            </BlueHeading>

            <SimpleGrid columns={[1, 1, 2]} gap={4}>
              <Box>
                <Card borderRadius={12} mb={4}>
                  <BlueHeading color={'blue.400'} p={4} borderBottom={'1px solid'} borderBottomColor={'gray.200'}>
                    {t('meterReading.title')}
                  </BlueHeading>
                  <CardBody p={0}>

                    <Box p={4} borderBottom={'1px solid'} borderBottomColor={'gray.200'}>
                      <Text mb={2} fontWeight={'bold'} textTransform={'uppercase'}>
                        {t('meterReading.form.text1')} {t('meterReading.form.text2')}
                      </Text>

                      <InputGroup>
                        <Input borderColor={isError ? 'red.400' : undefined} type={'number'} ref={inputRef} variant={'filled'} placeholder={t('meterReading.form.input')} isRequired />
                        <InputRightElement w={'80px'}>
                          <Button w={'100%'} bg={'blue.600'} textTransform={'uppercase'} color={'white'} _hover={{}} onClick={onSubmit}>
                            {t('meterReading.form.button')}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      <ErrorMessage show={isError}>Valore non corretto</ErrorMessage>
                    </Box>

                    <Box p={4} textAlign={'center'}>
                      <Text color={'gray.600'}>
                        {/*{t('meterReading.calendar.text', {*/}
                        {/*  dateFrom: formatDate(allReadings[0].column1),*/}
                        {/*  dateTo: formatDate(allReadings[allReadings.length - 1].column1),*/}
                        {/*})}*/}
                        Effettuando la lettura tra il <strong>{formatDate(allReadings[0].column1)} e il {formatDate(allReadings[allReadings.length - 1].column1)}</strong> la prossima bolletta sarà calcolata sui consumi
                        effettivi.
                      </Text>

                      <CardGreenButton mt={2} w={'auto'} borderRadius={12} as={'a'} href={content.meteringDetailsPage.banner1.link || '#'}>
                        <Image src={'/img/ic-memo-white@2x.webp'} m={2} h={'26px'} />
                        {t('meterReading.calendar.button')}
                      </CardGreenButton>
                    </Box>
                  </CardBody>
                </Card>

                <Card mb={0}>
                  <BlueHeading color={'blue.400'} p={4} borderBottom={'1px solid'} borderBottomColor={'gray.200'}>
                    Lettura contatore
                  </BlueHeading>
                  <CardBody>
                    <Box textAlign={'center'}>
                      <Text textAlign={'center'} color={'gray.600'}>
                        La informiamo che effettueremo la lettura del suo contatore nel periodo dal <strong>{formatDate(allReadings[0].column1)} al {formatDate(allReadings[allReadings.length - 1].column1)}</strong>. Vi preghiamo di lasciare
                        libero l'accesso del
                        contatore nei giorni indicati.
                      </Text>

                      <CardGreenButton mt={2} w={'auto'} borderRadius={12} onClick={meterReadingContactOnOpen}>
                        <Image src={'/img/ic-memo-white@2x.webp'} m={2} h={'26px'} />
                        Avvisami prima del passagio
                      </CardGreenButton>
                    </Box>
                  </CardBody>
                </Card>
              </Box>

              {readings.length > 0 && <Card mb={[4, 4, 0]}>
                <HStack justifyContent={'space-between'} p={4} borderBottom={'1px solid'} borderBottomColor={'gray.200'}>
                  <BlueHeading color={'blue.400'}>
                    {t('meterReading.table.title')}
                  </BlueHeading>

                  {years.length > 1 && <Select flexBasis={'100px'} onChange={onYearChange}>
                    {years.map(x => <option key={x} value={x}>{x}</option>)}
                  </Select>}
                </HStack>
                <CardBody>
                  <Table>
                    <Thead>
                      <Tr color={'blue.600'} fontSize={'sm'} textTransform={'uppercase'} fontWeight={'bold'}>
                        <Td py={0} px={4} pl={0} border={0}>{t('meterReading.table.date')}</Td>
                        <Td py={0} px={4} border={0}>{t('meterReading.table.type')}</Td>
                        <Td py={0} px={4} border={0} pr={0}>{t('meterReading.table.value')}</Td>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {readings.map(row => (
                        <Tr key={row.column1}>
                          <Td py={2} px={4} pl={0} border={0}>{formatDate(row.column1)}</Td>
                          <Td py={2} px={4} border={0}>{row.column2}</Td>
                          <Td py={2} px={4} pr={0} border={0}>{row.column3}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </CardBody>
              </Card>}
            </SimpleGrid>
          </Box>
        </Box>
      </SimpleGrid>

      <MeterReadingValueSubmittedModal isOpen={meterReadingValueIsOpen} onClose={meterReadingValueOnClose} />
      <MeterReadingContactModal isOpen={meterReadingContactIsOpen} onClose={meterReadingContactOnClose} />
    </>
  );
}