import Card from '../../Card';
import { Box, Hide, Show, SimpleGrid, Text } from '@chakra-ui/react';
import CardBody from '../../CardBody';
import CardGreenButton from '../../CardGreenButton';
import CTAButton from '../../CTAButton';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ContactAssistanceBox from '../../ContactAssistanceBox';
import ContactEmergencyBox from '../../ContactEmergencyBox';
import { useContext } from 'react';
import { AppContext } from '../../App';
import ContactsBranches from '../../views/ContactsBranches';
import ViewTitle from '../../ViewTitle';

export default function ViacquaContactsLayout({ openingHours }) {
  const location = useLocation();
  const { t } = useTranslation();
  const { setup } = useContext(AppContext);

  return (
    <>
      <ViewTitle>{t('contact.title')}</ViewTitle>
      <SimpleGrid columns={[1, 2]} templateColumns={[null, '1fr 2fr', '1fr 3fr']} p={4}>
        <Box>
          <Card>
            <CardBody p={0}>
              <Box p={4}>
                <Text textAlign={'center'} color={'gray.600'}>
                  Accedi allo sportello Web per vedere lo storico delle tue bollette e altre informazioni utili
                </Text>
              </Box>
              <CardGreenButton as={'a'} href={setup.webSite}>
                ACCEDI ALLO SPORTELLO WEB
              </CardGreenButton>
            </CardBody>
          </Card>

          <ContactAssistanceBox openingHours={openingHours}>
            <Text>
              Cosa puoi fare chiamando il servizio clienti: è possibile richiederci
              qualsiasi pratica contrattuale (es. attivare, chiudere o effettuare delle variazioni al
              contratto), inviandoci la richiesta con i moduli che trovi qui "link" oppure
              chiamando il servizio clienti. Prima di chiamare prendi l'ultima bolletta, carta
              d'identità e codice fiscale dell'intestatario del contratto.
            </Text>
          </ContactAssistanceBox>

          <ContactEmergencyBox>
            <Text mt={2}>
              {setup.emergencyNumber} da rete fissa a mobile <br />
              Attivo 24 ore su 24 tutti i giorni
            </Text>
          </ContactEmergencyBox>

          <Hide above={'sm'}>
            <CTAButton image={'/img/ic-sportelli@2x.webp'} to={`${location.pathname}/branches`}>
              {t('contact.branches.title')}
            </CTAButton>
          </Hide>
        </Box>

        <Show above={'sm'}>
          <ContactsBranches isPage={false} />
        </Show>
      </SimpleGrid>
    </>
  );
}