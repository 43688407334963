import { Box } from '@chakra-ui/react';
import ItemHeader from '../ItemHeader';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AverageConsumptionBox from '../AverageConsumptionBox';
import ConsumptionComparisonGraph from '../ConsumptionComparisonGraph';
import { useContext } from 'react';
import { AppContext } from '../App';

export default function ConsumptionDetailEfficiency() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { generateLink } = useContext(AppContext);

  return (
    <Box>
      <ItemHeader
        dir={'back'}
        title1={`${t('contract.title')} ${decodeURIComponent(id)}`}
        title2={t('consumption.detail.efficiency.title')}
        to={generateLink(`/consumption/${id}`)}
        icon={false} />
      <Box p={4}>
        <AverageConsumptionBox />
        <ConsumptionComparisonGraph />
      </Box>
    </Box>
  );
}