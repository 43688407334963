export const SERVICE_TYPES = {
  ELECTRICITY: 'ele',
  GAS: 'gas',
  H20: 'h2o',
};
export const COMPANIES = {
  EGEA: 'ww441',
  VIACQUA: 'ww456',
};

export const LANGUAGES = ['it', 'en', 'de', 'sk', 'hu', 'cz'];