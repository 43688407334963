import { Box, Flex, Image, Spacer, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { formatAmount, formatDate } from './utils';

export default function InvoiceItemTables({ tables }) {
  return (
    <Box overflow={'hidden'}>
      {tables.map(table => (
        <Box key={table.headers[0].description}>
          <Flex alignItems={'center'} my={4}>
            <Text color={'blue.600'} fontWeight={'bold'} fontSize={'xl'} textTransform={'uppercase'}>
              {table.headers[0].description}
            </Text>
            <Spacer />
            <Image src={'/img/ic-helper-swipe@2x.webp'} h={'28px'} mr={2} />
          </Flex>

          <Box overflowY={'scroll'} w={'100%'}>
            <Table variant={'striped'} colorScheme={'blackAlpha'}>
              <Thead>
                <Tr bg={'blue.300'}>
                  {table.columnTitles.map((x, i) => <Th key={i} color={'white'}>{x.description}</Th>)}
                </Tr>
              </Thead>

              <Tbody>
                {table.rows.map((row, i) => (
                  <Tr key={i}>
                    {Object.keys(row).map((col, j) => {
                      const type = table.columnTitles[j]?.type;
                      let val = row[col];

                      if (val && type === 'date') {
                        val = formatDate(val);
                      }

                      return <Td key={j}>{val}</Td>;
                    })}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>

          {table.footers.length > 0 && <Table my={4}>
            <Tbody>
              {table.footers.map((row, i) => (
                <Tr key={i}>
                  <Td pl={0} fontSize={'xl'} color={'blue.600'}>{row.description}</Td>
                  <Td pr={0} fontSize={'xl'} color={'blue.600'} fontWeight={'bold'} isNumeric>
                    {formatAmount(row.Value)}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          }
        </Box>
      ))}
    </Box>
  );
}