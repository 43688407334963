import Card from './Card';
import CardHeader from './CardHeader';
import { Box, Button, IconButton, Image, Table, Tbody, Td, Text, Thead, Tr } from '@chakra-ui/react';
import CardBody from './CardBody';
import { formatAmount, formatDate } from './utils';
import CardGreenButton from './CardGreenButton';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AppContext } from './App';

export default function UnpaidInvoicesBox() {
  const { t } = useTranslation();
  const { data, content } = useContext(AppContext);

  const unpaidTotal = data.previousUnpaidBills.reduce((total, bill) => {
    total += +(bill.amount.replace(',', '.'));
    return total;
  }, 0);

  if (unpaidTotal <= 0) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <Text>{t('profile.paymentStatusBox.title')}</Text>
      </CardHeader>
      <CardBody p={0} textAlign={'center'}>
        <Box p={4} borderBottom={1} borderStyle={'solid'} borderColor={'gray.100'}>
          <Text color={`red.600`} fontWeight={'bold'} textTransform={'uppercase'}>{t('profile.paymentStatusBox.text1')}</Text>
          <Text color={`red.600`} fontWeight={'bold'}>{t('profile.paymentStatusBox.text2', { amount: formatAmount(unpaidTotal) })}</Text>
          <Text>{t('profile.paymentStatusBox.text3')}</Text>
          <Button as={'a'} href={content.holderPage.paymentStatusBox.link || '#'} color={'blue.600'} textDecoration={'underline'} d={'inline-block'} variant={'link'} h={'auto'} _hover={{}} _focus={{}}>
            {t('profile.paymentStatusBox.link')}
          </Button>
        </Box>

        <Box px={4} mt={2}>
          <Table>
            <Thead>
              <Tr color={'blue.600'} fontSize={'sm'} fontWeight={'bold'}>
                <Td py={0} px={2} border={0}>Numero fattura</Td>
                <Td py={0} px={2} border={0}>Importo</Td>
                <Td py={0} px={2} border={0}>Scadenza</Td>
              </Tr>
            </Thead>
            <Tbody>
              {data.previousUnpaidBills.map(row => (
                <Tr key={row.number}>
                  <Td py={0} px={2}>{row.number}</Td>
                  <Td py={0} px={2} whiteSpace={'nowrap'}>{row.currencySimbol} {formatAmount(row.amount)}</Td>
                  <Td py={0} px={2}>{formatDate(row.dueDate)}</Td>
                  <Td p={0} textAlign={'right'}>
                    <IconButton color={'blue.600'} aria-label={'download'} variant={'unstyled'} icon={<Image m={'0 auto'} w={'24px'} src={'/img/ic-download@2x.webp'} />} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        <Box pos={'relative'} top={'-1px'} color={'blue.600'} fontWeight={'bold'} p={2} borderTop={1} borderStyle={'solid'} borderColor={'gray.200'}>
          <Text>{t('profile.paymentStatusBox.text4')}</Text>
          <Text textTransform={'uppercase'}>{t('profile.paymentStatusBox.text5')}</Text>
        </Box>

        <CardGreenButton as={'a'} href={content.holderPage.paymentStatusCta.link || '#'}>
          <Image src={content.holderPage.paymentStatusCta.imageUrl || '/img/ic-btn-bank@2x.webp'} m={2} h={'26px'} />
          {content.holderPage.paymentStatusCta.text || t('profile.paymentStatusBox.CTAlink')}
        </CardGreenButton>
      </CardBody>
    </Card>
  );
}