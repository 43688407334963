import Card from '../../Card';
import { Box, Image, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import CardBody from '../../CardBody';
import CardGreenButton from '../../CardGreenButton';
import CTAButton from '../../CTAButton';
import { AppContext } from '../../App';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ContactAssistanceBox from '../../ContactAssistanceBox';
import ContactEmergencyBox from '../../ContactEmergencyBox';

export default function EgeaContactsLayout({ openingHours }) {
  const { setup, content } = useContext(AppContext);
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Box p={4}>
      <Card>
        <Image src={content.contactsPage.banner1.imageUrl || '/img/banner-login@2x.webp'} />
        <CardBody p={0}>
          <CardGreenButton as={'a'} href={content.contactsPage.banner1.link || '#'}>
            {t('contact.box1.link')}
          </CardGreenButton>
        </CardBody>
      </Card>

      <Text mt={2} fontSize={'2xl'} color={'blue.600'} textTransform={'uppercase'} fontWeight={'bold'}>
        {t('contact.title')}
      </Text>

      <Table variant={'simple'}>
        <Tbody>
          <Tr>
            <Td px={0}>
              <Text color={'blue.600'} textTransform={'uppercase'} fontSize={'sm'}>{t('contact.phoneNumber')}</Text>
              <Text mt={2} fontWeight={'bold'} fontSize={'xl'}>{setup.tollFreeNumber}</Text>
            </Td>
          </Tr>

          <Tr>
            <Td px={0}>
              <Text color={'blue.600'} textTransform={'uppercase'} fontSize={'sm'}>{t('contact.web')}</Text>
              <Text as={'a'} mt={2} display={'block'} href={setup.webSite} fontWeight={'bold'} fontSize={'xl'}>{setup.webSite}</Text>
            </Td>
          </Tr>

          <Tr>
            <Td px={0}>
              <Text color={'blue.600'} textTransform={'uppercase'} fontSize={'sm'}>{t('contact.urgentPhoneNumber')}</Text>
              <Text mt={2} fontWeight={'bold'} fontSize={'xl'}>{setup.tollFreeNumber}</Text>
            </Td>
          </Tr>

          <Tr>
            <Td px={0} border={0}>
              <Text color={'blue.600'} textTransform={'uppercase'} fontSize={'sm'}>{t('contact.reclamation')}</Text>
              <Text as={'a'} href={`mailto:${setup.customerServiceEmail}`} mt={2} display={'block'} fontWeight={'bold'} fontSize={'xl'}>{setup.customerServiceEmail}</Text>
            </Td>
          </Tr>
        </Tbody>
      </Table>

      <CTAButton image={'/img/ic-sportelli@2x.webp'} to={`${location.pathname}/branches`}>
        {t('contact.branches.title')}
      </CTAButton>

      <ContactAssistanceBox openingHours={openingHours} />

      <ContactEmergencyBox>
        <Text mt={2}>
          {t('contact.box2.text')}
        </Text>
      </ContactEmergencyBox>
    </Box>
  );
}