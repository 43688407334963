import { Box, Button, Checkbox, Flex, Input, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text } from '@chakra-ui/react';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from './App';
import { FaRegCheckCircle } from 'react-icons/fa';
import { isEmail, isPhoneNumber } from './utils';
import ErrorMessage from './ErrorMessage';

export default function MeterReadingContactModal({ isOpen, onClose }) {
  let valueRef = useRef(), typeRef = useRef(), inputRef = useRef();
  const { t } = useTranslation();
  const { data } = useContext(AppContext);
  const [showCustomContact, setShowCustomContact] = useState(false);
  const [isError, setIsError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [onSubmitMessage, setOnSubmitMessage] = useState('');

  const onValueChange = (e) => setShowCustomContact(!e.target.value);
  const onTypeChange = (e) => inputRef.setAttribute('type', e.target.value);

  const onCancel = () => {
    setShowCustomContact(false);
    setSubmitted(false);
    setOnSubmitMessage('');
    onClose();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let type, value;
    const types = {
      'tel': 'Telefono',
      'email': 'E-mail',
    };
    if (!showCustomContact) {
      [type, value] = valueRef.value.split('|');
    } else {
      type = typeRef.value;
      value = inputRef.value;

      if ((type === 'email' && !isEmail(value)) ||
        (type === 'tel' && !isPhoneNumber(value))) {
        setIsError(true);
        return;
      }
    }

    setSubmitted(true);
    setShowCustomContact(false);
    setOnSubmitMessage((showCustomContact ? 'Altro recapito - ' : '') + `${types[type]} (${value})`);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onSubmit}>
          <ModalHeader p={2} color={'white'} bg={'blue.600'} textTransform={'uppercase'} borderTopRadius={6}>
            Avviso di passaggio
          </ModalHeader>
          <ModalCloseButton onClick={onClose} color={'white'} />
          <ModalBody>
            {!submitted && <>
              <Text my={2}>Indica attraverso quale canale intendi essere ricontattato. Puoi utilizzare uno dei recapiti già presenti in anagrafica o indicare un altro recapito.</Text>
              <Select onChange={onValueChange} ref={x => valueRef = x} placeholder='Seleziona recapito' isRequired>
                {Object.values(data.phoneNumber).map(x => <option key={x} value={`tel|${x}`}>Telefono ({x})</option>)}
                {Object.values(data.email).map(x => <option key={x} value={`email|${x}`}>E-mail ({x})</option>)}
                <option value=''>Altro recapito</option>
              </Select>

              {showCustomContact && <>
                <Text mb={1} mt={2} color={'blue.600'} size={'xl'}>Specifica recapito</Text>
                <Flex>
                  <Select onChange={onTypeChange} ref={x => typeRef = x} placeholder='Tipologia' bg={'blue.400'} color={'white'} isRequired>
                    <option value='tel'>Telefono</option>
                    <option value='email'>E-mail</option>
                  </Select>
                  <Input borderColor={isError ? 'red.400' : undefined} type={'text'} ref={x => inputRef = x} mx={2} placeholder={'insersci'} isRequired />
                </Flex>
                <Box>
                  <ErrorMessage show={isError}>Valore non corretto</ErrorMessage>
                </Box>
              </>}
              <Box mt={4}>
                <Checkbox isRequired>
                  Autorizzo il trattamento dei miei dati personali secondo le modalità descritte nell' <Link color={'blue.400'} textDecoration={'underline'} href='#'>Informativa sulla privacy</Link>
                </Checkbox>
              </Box>
            </>}

            {submitted && <Box textAlign={'center'}>
              <Text color={'blue.600'} fontSize={'xl'} fontWeight={'bold'}>
                Recapito inserito correttamente!
              </Text>
              <Box my={8} fontSize={'9em'} color={'green.400'}>
                <FaRegCheckCircle style={{ margin: '0 auto' }} />
              </Box>
              <Text fontSize={'lg'}>
                Verrai ricontattato dai nostri operatori per fissare giorno e ora della lettura.
                Il tuo recapito:
              </Text>
              <Text fontSize={'lg'} fontWeight={'bold'} textTransform={'uppercase'}>{onSubmitMessage}</Text>
            </Box>}
          </ModalBody>

          <ModalFooter textTransform={'uppercase'} borderTop={'1px'} borderColor={'gray.200'}>
            {!submitted && <>
              <Button onClick={onCancel} variant='ghost' color={'green.400'} border={'1px'} borderColor={'green.400'} mr={3} hover={{}}>
                Annulla
              </Button>

              <Button type={'submit'} color={'white'} bg={'green.400'} _hover={{}}>
                Conferma
              </Button>
            </>}

            {submitted && <>
              <Button onClick={onClose} color={'white'} bg={'green.400'} _hover={{}}>
                Chiudi
              </Button>
            </>}
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}