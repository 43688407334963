import BlueHeading from './BlueHeading';
import { Chart } from 'react-google-charts';
import { useTranslation } from 'react-i18next';
import { AppContext } from './App';
import { useContext } from 'react';
import { Box } from '@chakra-ui/react';

export default function ConsumptionGraph({ contract }) {
  const { t } = useTranslation();
  const { appState } = useContext(AppContext);

  if (!contract.contractConsumptions.length) {
    return null;
  }

  let graphData = contract.contractConsumptions.map(x => {
    let val = +x.totalValue.replace(',', '.');
    return [x.period, val];
  });
  graphData.unshift(['Date', contract.contractConsumptions[0].type]);

  if (graphData.length === 1) {
    return null;
  }

  return (
    <Box mb={[0, 4]}>
      <BlueHeading color={'blue.400'}>{t(`consumption.detail.graphTitle.${appState.type}`)}</BlueHeading>
      <Chart
        width={'100%'}
        height={300}
        chartType='ColumnChart'
        loader={<div>Loading Chart</div>}
        data={graphData}
        options={{
          chartArea: { width: '60%' },
          hAxis: {
            minValue: 0,
          },
        }}
      />
    </Box>
  );
}