import { Box, Button, Image, SimpleGrid, Text } from '@chakra-ui/react';
import AverageConsumptionBox from '../../AverageConsumptionBox';
import ConsumptionGraph from '../../ConsumptionGraph';
import CardBody from '../../CardBody';
import Card from '../../Card';
import BlueHeading from '../../BlueHeading';
import { useTranslation } from 'react-i18next';

export default function ViacquaConsumptionDetailLayout({ contract }) {
  const { t } = useTranslation();
  return (
    <>
      <Card>
        <BlueHeading color={'blue.400'} p={4} borderBottom={'1px solid'} borderBottomColor={'gray.200'}>
          {t('consumption.title')} - {t('contract.title')} {contract.contractNumber}
        </BlueHeading>
        <CardBody>
          <SimpleGrid columns={[1, 1, 2]}>
            <ConsumptionGraph contract={contract} />
            <AverageConsumptionBox />
          </SimpleGrid>
        </CardBody>
      </Card>

      <SimpleGrid columns={[1, 2]} gap={4}
                  textShadow={'1px 1px black'}
                  fontSize={['1.2rem', '1.8vw']}
                  lineHeight={1.4}
                  textTransform={'uppercase'}
                  color={'white'} mb={4}>
        <Box pos={'relative'}>
          <Box pos={'absolute'} left={'5%'} bottom={'10%'}>
            <Text>EDUCAZIONE AMBIENTALE</Text>
            <Text fontWeight={'bold'}>PROPOSTE PER LE SCUOLE E APPROFONDIMENTO PER I RAGAZZI</Text>
            <Button px={8} mt={2} as={'a'} href={'#'} textShadow={'none'} variant={'ghost'} bg={'rgba(255,255,255, 0.4)'}>
              Scopri di più
            </Button>
          </Box>
          <Image src={'/img/consumption-banner-h2o-2.jpg'} borderRadius={12} />
        </Box>
        <Box pos={'relative'}>
          <Box pos={'absolute'} left={'5%'} bottom={'10%'}>
            <Text>Qualità acqua</Text>
            <Text fontWeight={'bold'}>conoscI le caratteristiche dell'acqua che esce dal tuo rubinetto</Text>
            <Button px={8} mt={2} as={'a'} href={'#'} textShadow={'none'} variant={'ghost'} bg={'rgba(255,255,255, 0.4)'}>
              Scopri di più
            </Button>
          </Box>
          <Image src={'/img/consumption-banner-h2o-1.jpg'} borderRadius={12} />
        </Box>
      </SimpleGrid>
    </>
  );
}