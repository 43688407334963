import { Box, HStack, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import BlueHeading from './BlueHeading';

export default function AverageConsumptionBox() {
  const { t } = useTranslation();
  const average = 51;
  const current = 58;
  const diff = ((current / average) * 100 - 100).toFixed(0);

  return (
    <Box color={'gray.600'} textAlign={'center'}>
      <BlueHeading mb={4} color={'blue.400'} textAlign={'left'}>{t('consumption.detail.efficiency.text1')}</BlueHeading>
      <Text mb={4}>{t('consumption.detail.efficiency.text2')}</Text>

      <HStack pos={'relative'} py={12} fontSize={'xl'} fontWeight={'bold'}>

        <Box pos={'absolute'} top={0} left={`${current}%`} transform={'translateX(-50%)'} m={'0 !important'}>
          <Box pos={'absolute'} left={'calc(50% - 15px)'} bottom={'-14px'} w={0} h={0} borderLeft={'15px solid transparent'} borderRight={'15px solid transparent'} borderTop={'15px solid'} borderTopColor={'gray.600'} />
          <Text>Tu: {current} Smc</Text>
        </Box>

        <Image src={'/img/ic-good@2x.png'} w={'36px'} />
        <Box borderRadius={'36px'} h={'36px'} w={'100%'} bg={'linear-gradient(to right, #008fcf, #c1186c)'} />
        <Image src={'/img/ic-bad@2x.png'} w={'36px'} />

        <Box pos={'absolute'} bottom={0} left={`${average}%`} transform={'translateX(-50%)'} m={'0 !important'}>
          <Box pos={'absolute'} left={'calc(50% - 15px)'} top={'-14px'} w={0} h={0} borderLeft={'15px solid transparent'} borderRight={'15px solid transparent'} borderBottom={'15px solid'} borderBottomColor={'gray.600'} />
          <Text>Media: {average} Smc</Text>
        </Box>
      </HStack>

      <Text>
        <Text verticalAlign={'middle'} color={'#c1186c'} fontWeight={'bold'} fontSize={'3xl'} as={'span'}>{diff}%</Text> {t('consumption.detail.efficiency.text3')}
      </Text>
    </Box>
  );
}