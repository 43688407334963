import { Box, Button, Image, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { AppContext } from '../App';

export default function SplashScreen() {
  const { setup, logEvent } = useContext(AppContext);
  const { search, pathname } = window.location;

  useEffect(() => {
    const params = new URLSearchParams(search);
    const messageId = params.get('messageId');
    const channel = params.get('channel');

    if (messageId) {
      sessionStorage.setItem('messageId', messageId);
    }

    if (channel) {
      sessionStorage.setItem('channel', channel);
    }
  }, []);

  return (
    <Box id='main'>
      <Box bg={'white'} opacity={0.7} w={'100%'} h={'100px'} textAlign={'center'}>
        <Image src={`/img/logo-${setup.name.toLowerCase()}.webp`} h={'100%'} alt='' p={4} m={'0 auto'} />
      </Box>
      <Box as={Link} to={`${pathname}/profile`}
           display={'block'} pos={'relative'} h={'calc(100vh - 100px)'}
           bg={`url(/img/splash-image-${setup.name.toLowerCase()}.webp) center center no-repeat`}>

        <Box pos={'absolute'} left={'10%'} top={'30%'} color={'white'} textTransform={'uppercase'}>
          <Text fontSize={'2xl'} mb={4}>L'ACQUA è VITA</Text>

          <Text fontSize={'3xl'} fontWeight={'bold'} mb={8}>
            Viacqua è il suo <br />
            percorso
          </Text>

          <Button bg={'gray.400'}>ACCEDI</Button>
        </Box>
      </Box>
    </Box>
  );
}