import {
  Box,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import Card from './Card';
import { useContext, useRef, useState } from 'react';
import { AppContext } from './App';
import { useTranslation } from 'react-i18next';
import CardHeader from './CardHeader';
import CardGreenButton from './CardGreenButton';
import { FaEnvelope, FaPhone, FaRegCheckCircle, FaTrashAlt } from 'react-icons/fa';
import AddContactForm from './AddContactForm';

const ClientInfoInputGroup = ({ rows, type, icon, refs, onClick }) => (
  rows.map((x, i) => <Flex key={x} mb={4}>
    <InputGroup>
      <InputLeftElement color={'blue.600'} pointerEvents='none' children={icon} />
      <Input ref={el => refs.current[i] = el} type={type} defaultValue={x} isRequired />
    </InputGroup>
    <IconButton disabled={rows.length < 2} onClick={() => onClick(x)} color={'red'} ml={2} variant={'ghost'} icon={<FaTrashAlt />} aria-label={'delete'} />
  </Flex>)
);


export default function ClientInfoBox() {
  let phoneRefs = useRef([]), emailRefs = useRef([]);
  const { data, content, IS_SERVICE_H2O } = useContext(AppContext);
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [phones, setPhones] = useState(Object.values(data.phoneNumber));
  const [emails, setEmails] = useState(Object.values(data.email));
  const [submitted, setSubmitted] = useState(false);

  const removePhone = (phone) => setPhones(phones.filter(x => x !== phone));
  const removeEmail = (email) => setEmails(emails.filter(x => x !== email));

  const cancelSave = () => {
    setPhones(Object.values(data.phoneNumber));
    setEmails(Object.values(data.email));
    onClose();
  };

  const saveInfo = (e) => {
    e.preventDefault();
    setPhones(phoneRefs.current.filter(x => x && !!x.value).map(x => x.value));
    setEmails(emailRefs.current.filter(x => x && !!x.value).map(x => x.value));
    // todo log
    setSubmitted(true);
  };

  const addContact = (type, value) => {
    switch (type) {
      case 'tel':
        setPhones([...phones, value]);
        break;
      case 'email':
        setEmails([...emails, value]);
        break;
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Text>Intestatario utenze</Text>
        </CardHeader>
        <Flex p={4}>
          <Image pr={4} py={1} mt={1} h={'60px'} src='/img/placeholder-profile@2x.webp' />
          <Box color={'blue.600'} flex={1}>
            <Text fontSize={'2xl'} fontWeight={'bold'}>{data.companyName || `${data.name} ${data.surname}`}</Text>
            <Text>{IS_SERVICE_H2O ? 'P.IVA/C.F.' : ''} {data.taxCode || data.vatNumber}</Text>
            <Text>{t('profile.clientCode')} {data.clientID}</Text>
          </Box>
          {/*<IconButton onClick={onOpen} variant={'ghost'} aria-label={t('profile.edit')}>*/}
          {/*  <Image h={'20px'} src='/img/ic-edit-profile@2x.webp' />*/}
          {/*</IconButton>*/}
        </Flex>

        <Flex p={4}>
          <VStack flex={1} alignItems={'flex-start'} justifyContent={'center'}>
            <Flex alignItems={'center'} py={2} w={'100%'} borderBottom={1} borderColor={'gray.200'} borderStyle={'solid'}>
              <Image h={'24px'} src='/img/ic-profile-address@2x.webp' />
              <Text px={4}>
                {data.billAddress.street} {data.billAddress.number}<br />
                {data.billAddress.postalCode} {data.billAddress.city} ({data.billAddress.province}) - {data.billAddress.country}
              </Text>
            </Flex>

            <Flex alignItems={'center'} py={2} w={'100%'} borderBottom={1} borderColor={'gray.200'} borderStyle={'solid'}>
              <Image h={'24px'} src='/img/ic-profile-phone@2x.webp' />
              <Box>
                {phones.map((x, i) => <Text key={i} px={4}>{x}</Text>)}
              </Box>
            </Flex>

            <Flex alignItems={'center'} pt={2} w={'100%'}>
              <Image h={'24px'} src='/img/ic-profile-email@2x.webp' />
              <Box>
                {emails.map((x, i) => <Text key={i} px={4}>{x}</Text>)}
              </Box>
            </Flex>
          </VStack>
        </Flex>

        <Box borderTop={1} borderColor={'gray.200'} borderStyle={'solid'} textAlign={'center'}>
          <Text textTransform={'uppercase'} fontWeight={'bold'} color={'blue.600'} p={4}>
            CONTROLLA I TUOI DATI. <br /> Se non sono aggiornati modificali ora.
          </Text>
          <CardGreenButton onClick={onOpen}>
            <Image src={content.holderPage.paymentStatusCta.imageUrl || '/img/ic-btn-bank@2x.webp'} m={2} h={'26px'} />
            MODIFICALI ORA
          </CardGreenButton>
        </Box>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={saveInfo}>
            <ModalHeader p={2} color={'white'} bg={'blue.600'} textTransform={'uppercase'} borderTopRadius={6}>
              Modifica dati intestatario
            </ModalHeader>
            <ModalCloseButton onClick={cancelSave} color={'white'} />
            <ModalBody>
              {!submitted && <>
                <Text color={'blue.600'} fontSize={'xl'}>Dati di contatto</Text>
                <Text>è possibile inserire più contatti telefonici / email.</Text>
                <Text mb={4} textDecoration={'underline'}>Attenzione: è richiesto almeno un dato di contatto.</Text>

                <SimpleGrid>
                  <ClientInfoInputGroup rows={phones} type={'tel'} refs={phoneRefs} icon={<FaPhone />} onClick={(x) => removePhone(x)} />
                  <ClientInfoInputGroup rows={emails} type={'email'} refs={emailRefs} icon={<FaEnvelope />} onClick={(x) => removeEmail(x)} />
                  <Text mb={2} color={'blue.600'} size={'xl'}>Aggiungi contatto</Text>
                  <AddContactForm onSubmit={addContact} />
                  <Box mt={4}>
                    <Checkbox isRequired>
                      Autorizzo il trattamento dei miei dati personali secondo le modalità descritte nell' <Link color={'blue.400'} textDecoration={'underline'} href='#'>Informativa sulla privacy</Link>
                    </Checkbox>
                  </Box>
                </SimpleGrid>
              </>}

              {submitted && <Box textAlign={'center'}>
                <Text color={'blue.600'} fontSize={'xl'} fontWeight={'bold'}>Dati aggiornati correttamente!</Text>
                <Box my={8} fontSize={'9em'} color={'green.400'}>
                  <FaRegCheckCircle style={{ margin: '0 auto' }} />
                </Box>
                <Text fontSize={'lg'}>
                  I tuoi dati sono stati aggiornati correttamente. La modifica sarà visibile solo dalla successiva bolletta.
                </Text>
              </Box>}
            </ModalBody>

            <ModalFooter textTransform={'uppercase'} borderTop={'1px'} borderColor={'gray.200'}>
              {!submitted && <>
                <Button onClick={cancelSave} variant='ghost' color={'green.400'} border={'1px'} borderColor={'green.400'} mr={3} hover={{}}>
                  Annulla
                </Button>
                <Button type={'submit'} color={'white'} bg={'green.400'} _hover={{}}>
                  Salva modifiche
                </Button>
              </>}

              {submitted && <>
                <Button onClick={onClose} color={'white'} bg={'green.400'} _hover={{}}>
                  Chiudi
                </Button>
              </>}
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}