import { Box, Image, Text } from '@chakra-ui/react';
import ItemHeader from '../ItemHeader';
import Card from '../Card';
import CardBody from '../CardBody';
import CardHeader from '../CardHeader';
import CardGreenButton from '../CardGreenButton';
import Flicking from '@egjs/react-flicking';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AppContext } from '../App';

export default function ConsumptionDetailSustainability() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { generateLink } = useContext(AppContext);

  return (
    <Box>
      <ItemHeader
        dir={'back'}
        title1={`${t('contract.title')} ${decodeURIComponent(id)}`}
        title2={t('consumption.detail.sustainability.title')}
        to={generateLink(`/consumption/${id}`)}
        icon={false} />

      <Box p={4}>
        <Card>
          <CardBody textAlign={'center'}>
            <Text textTransform={'uppercase'} fontSize={'sm'} color={'gray.400'}>
              {t('consumption.detail.sustainability.box1.text1')}
            </Text>
            <Text fontWeight={'bold'} fontSize={'2xl'} color={'blue.600'}>
              {t('consumption.detail.sustainability.box1.text2')}
            </Text>

            <Flicking>
              <Box w={'100%'} color={'gray.400'}>
                <Image m={'10px auto'} src={'/img/img-sample-freezer@2x.webp'} h={'80px'} />
                <Text>{t('consumption.detail.sustainability.box1.text3')}</Text>
              </Box>
              <Box w={'100%'} color={'gray.400'}>
                <Image m={'10px auto'} src={'/img/img-sample-freezer@2x.webp'} h={'80px'} />
                <Text>{t('consumption.detail.sustainability.box1.text4')}</Text>
              </Box>
            </Flicking>
          </CardBody>
        </Card>

        <Card>
          <CardBody textAlign={'center'}>
            <Text textTransform={'uppercase'} fontSize={'sm'} color={'gray.400'}>
              {t('consumption.detail.sustainability.box2.text1')}
            </Text>
            <Text fontWeight={'bold'} fontSize={'2xl'} color={'blue.600'}>
              {t('consumption.detail.sustainability.box2.text2')}
            </Text>

            <Flicking>
              <Box w={'100%'}>
                <Image m={'10px auto'} src={'/img/img-sample-riello@2x.webp'} h={'80px'} />
                <Text>{t('consumption.detail.sustainability.box2.text3')}</Text>
              </Box>
              <Box w={'100%'}>
                <Image m={'10px auto'} src={'/img/img-sample-riello@2x.webp'} h={'80px'} />
                <Text>{t('consumption.detail.sustainability.box2.text4')}</Text>
              </Box>
            </Flicking>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>{t('consumption.detail.sustainability.box3.title')}</CardHeader>
          <CardBody p={0} textAlign={'center'}>
            <Box p={4}>
              <Image m={'20px auto'} h={'80px'} src={'/img/ico-contatore@2x.webp'} />
              <Text color={'gray.400'}>{t('consumption.detail.sustainability.box3.text1')}</Text>
            </Box>
            <CardGreenButton as={Link} to={`/meter-reading/${id}`}>
              {t('consumption.detail.sustainability.box3.link')}
            </CardGreenButton>
          </CardBody>
        </Card>
      </Box>
    </Box>
  );
}