import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AppContext } from './App';

const NAV_ITEMS = [
  { label: 'nav.profile', to: '/profile', icon: '/img/ic-profile@2x.webp?v=1', activeIcon: '/img/ic-profile-active@2x.webp?v=1', isActive: (url) => url.includes('/profile') },
  { label: 'nav.invoices', to: '/invoices', icon: '/img/ic-bill@2x.webp?v=1', activeIcon: '/img/ic-bill-active@2x.webp?v=1', isActive: (url) => url.includes('/invoice') },
  { label: 'nav.consumption', to: '/consumption', icon: '/img/ic-consumption@2x.webp?v=1', activeIcon: '/img/ic-consumption-active@2x.webp?v=1', isActive: (url) => url.includes('/consumption') },
  { label: 'nav.meterReading', to: '/meter-reading', icon: '/img/ic-reading@2x.webp?v=1', activeIcon: '/img/ic-reading-active@2x.webp?v=1', isActive: (url) => url.includes('/meter-reading') },
  { label: 'nav.contacts', to: '/contacts', icon: '/img/ic-contacts@2x.webp?v=1', activeIcon: '/img/ic-contacts-active@2x.webp?v=1', isActive: (url) => url.includes('/contacts') },
];

const UnpaidMark = () => <Box w={'10px'} h={'10px'} bg={'red.400'} pos={'absolute'} top={'14%'} left={'55%'} borderRadius={'50%'} />;

export default function Footer() {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { generateLink, data } = useContext(AppContext);

  return (
    <Flex
      justifyContent={'space-between'}
      h={'60px'} borderTop={1} borderStyle={'solid'} borderColor={'gray.200'}>
      {NAV_ITEMS.map(x => (
        <Button position={'relative'} d={'flex'} borderRadius={0} flexDir={'column'} as={Link} key={x.label}
                to={generateLink(x.to)} p={2} variant={'ghost'} h={'100%'} aria-label={t(x.label)} _focus={{}}>
          {x.label === 'nav.profile' && data.previousUnpaidBills.length > 0 && <UnpaidMark />}
          <Image m={'0 auto 4px auto'} h={'22px'} src={x.isActive(pathname) ? x.activeIcon : x.icon} />
          <Text fontSize={'small'} color={x.isActive(pathname) ? 'blue.600' : 'gray.500'} textTransform={'uppercase'}>{t(x.label)}</Text>
        </Button>))}
    </Flex>
  );
}