import { Show, Text } from '@chakra-ui/react';

export default function ViewTitle({ children }) {
  return (
    <Show above={'sm'}>
      <Text fontSize={'2xl'} color={'white'} textTransform={'uppercase'} bg={'blue.400'} lineHeight={2.2} px={4} fontWeight={'bold'}>
        {children}
      </Text>
    </Show>
  );
}