import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import BlueHeading from '../BlueHeading';
import ContractList from '../ContractList';
import { useContext } from 'react';
import { AppContext } from '../App';
import { useTranslation } from 'react-i18next';
import ClientInfoBox from '../ClientInfoBox';
import UnpaidInvoicesBox from '../UnpaidInvoicesBox';
import ViewTitle from '../ViewTitle';
import SuggestionBox from '../SuggestionBox';

export default function Profile() {
  const { t } = useTranslation();
  const { data, IS_SERVICE_H2O } = useContext(AppContext);

  return (
    <>
      <ViewTitle>{t('nav.profile')}</ViewTitle>
      <SimpleGrid columns={[1, 2]} gap={4} p={4}>
        <Box>
          <ClientInfoBox />
          <UnpaidInvoicesBox />
        </Box>

        <Box>
          <BlueHeading mb={4}>{t('contract.listTitle')}</BlueHeading>
          <ContractList contracts={data.contracts} />
          {IS_SERVICE_H2O && <SuggestionBox title={'7 Aprile 2022'}>
            <Text>La giornata Mondiale dell'Acqua 2022</Text>
            <Text>Scopri di più</Text>
          </SuggestionBox>}
        </Box>
      </SimpleGrid>
    </>
  );
}