import { Box, Button, Image, Text } from '@chakra-ui/react';

export default function SuggestionBox({ title, href, children, image }) {

  return (
    <Button as={href ? 'a' : Box} href={href} variant={'ghost'} textAlign={'left'} w={'100%'} h={'auto'} _hover={{}} _focus={{}}
            bgImage={'/img/sfondo@2x.webp'} bgSize={'cover'} bgPos={'center'} bgRepeat={'no-repeat'} alignItems={'center'} justifyContent={'flex-start'} borderRadius={12}>
      <Image src={image || '/img/light-active@2x.webp'} m={2} h={'56px'} />
      <Box color={'white'} my={4} lineHeight={1.4}>
        <Text fontSize={'2xl'} fontWeight={'bold'}>{title}</Text>
        {children}
      </Box>
    </Button>
  );
}