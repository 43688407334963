import { COMPANIES, SERVICE_TYPES } from './constants';

export const domainConfigs = {
  'localhost:3000': {
    // company: COMPANIES.EGEA,
    // type: SERVICE_TYPES.ELECTRICITY,
    company: COMPANIES.VIACQUA,
    type: SERVICE_TYPES.H20,
  },
  'bollettainterattiva-viacqua.graphicscalve.it': {
    company: COMPANIES.VIACQUA,
    type: SERVICE_TYPES.H20,
  },
  'bollettainterattiva.phin.it': {
    company: COMPANIES.EGEA,
    type: SERVICE_TYPES.ELECTRICITY,
  },
  'bollettainterattiva-ppr.egea.it': {
    company: COMPANIES.EGEA,
    type: SERVICE_TYPES.ELECTRICITY,
  },
  'smartinvoice.zelenaposta.tatrabilling.sk': {
    company: COMPANIES.EGEA,
    type: SERVICE_TYPES.ELECTRICITY,
  },
  'smartinvoice.remices.sk': {
    company: COMPANIES.VIACQUA,
    type: SERVICE_TYPES.H20,
  },
};
