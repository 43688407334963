import { Flex } from '@chakra-ui/react';

export default function Card({ children, ...rest }) {
  return (
    <Flex
      overflow={'hidden'}
      mb={4}
      borderRadius={12}
      bg={'white'}
      boxShadow={'0 2px 6px lightgray'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      {...rest}>
      {children}
    </Flex>
  );
}