import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import ItemHeader from '../ItemHeader';
import { useContext } from 'react';
import { AppContext } from '../App';
import { decodeInvNumber } from '../utils';
import { useTranslation } from 'react-i18next';
import InvoiceItemTables from '../InvoiceItemTables';

export default function InvoiceItem() {
  const { id, contractId } = useParams();
  const { t } = useTranslation();
  const { generateLink, data } = useContext(AppContext);
  const bill = data.lastBills.find(x => x.number === decodeInvNumber(id));
  const billSplit = bill.lastBillSplit.find(x => x.contractNumber === contractId);

  return (
    <Box>
      <ItemHeader
        dir={'back'}
        title1={t('invoice.item.title')}
        title2={`${t('contract.title')} ${billSplit.contractNumber}`}
        to={generateLink(`/invoice/${id}`)}
        icon={false} />

      <Box p={4} pt={0}>
        <InvoiceItemTables tables={billSplit.splitTable} />
      </Box>
    </Box>
  );
}