import { Button, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import BlueHeading from './BlueHeading';
import { useTranslation } from 'react-i18next';

export default function MeterReadingValueSubmittedModal({ isOpen, onClose }) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader p={2} color={'white'} bg={'blue.600'} textTransform={'uppercase'} borderTopRadius={6}>
          {t('meterReading.title')}
        </ModalHeader>
        <ModalCloseButton onClick={onClose} color={'white'} />
        <ModalBody>
          <Flex direction={'column'} alignItems={'center'} textAlign={'center'}>
            <BlueHeading mt={4}>{t('meterReading.confirmed.title')}</BlueHeading>
            <Image src={'/img/img_confirm@2x.webp'} h={'180px'} />
            <Text fontSize={'xl'}>
              {t('meterReading.confirmed.text1')}<br />
              {t('meterReading.confirmed.text2')}
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter textTransform={'uppercase'} borderTop={'1px'} borderColor={'gray.200'}>
          <Button onClick={onClose} color={'white'} bg={'green.400'} _hover={{}}>
            Chiudi
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}