import Card from '../../Card';
import CardBody from '../../CardBody';
import { Box, Image, Text } from '@chakra-ui/react';
import CTAButton from '../../CTAButton';
import CardHeader from '../../CardHeader';
import CardGreenButton from '../../CardGreenButton';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../App';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ConsumptionGraph from '../../ConsumptionGraph';
import BlueHeading from '../../BlueHeading';

export default function EgeaConsumptionDetailLayout({ contract }) {
  const { data } = useContext(AppContext);
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <Card>
        <BlueHeading color={'blue.400'} p={4} borderBottom={'1px solid'} borderBottomColor={'gray.200'}>
          {t('consumption.title')} - {t('contract.title')} {contract.contractNumber}
        </BlueHeading>
        <CardBody>
          <ConsumptionGraph contract={contract} />
        </CardBody>
      </Card>

      <Box my={4}>
        <CTAButton image={'/img/ic-energy-efficiency@2x.webp'} to={`${location.pathname}/efficiency`}>
          {t('consumption.detail.efficiency.title')}
        </CTAButton>
      </Box>

      <Card>
        <CardHeader>{t('consumption.detail.sustainability.title')}</CardHeader>
        <CardBody p={0} textAlign={'center'}>
          <Box p={4}>
            <Text fontWeight={'bold'} color={'blue.600'} fontSize={'2xl'}>
              {t('consumption.detail.environmentBox.text1', { name: data.companyName || `${data.name} ${data.surname}` })}
            </Text>
            <Text color={'gray.400'}>
              {t('consumption.detail.environmentBox.text2')}
            </Text>
            <Image m={'20px auto'} h={'140px'} src={'/img/img-sostenibilita-ambientale@2x.webp'} />
          </Box>

          <CardGreenButton as={Link} to={`${location.pathname}/sustainability`}>
            {t('consumption.detail.environmentBox.link')}
          </CardGreenButton>
        </CardBody>
      </Card>
    </>
  );
}